import React, { useEffect } from "react";
import { Spinner } from '@fluentui/react/lib/Spinner';

export const App: React.FunctionComponent = () => {

  const [loading, setLoading] = React.useState(true);
  const [text, setText] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const externalLinkId = params.get('id');
  const calledOnce = React.useRef(false);

  const fetchData = async (id: string | null) => {
    // Get File extension
    var response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/link/external/filename?externalLinkId=${id}`, {
      method: "GET",
      headers: {
        Accept: 'application/text', 
        ContentType: 'application/text'
      },
    });
    if (response.ok) {
      var filename = await response.text();
      var extension = filename ? filename.split('.').pop()?.toLocaleLowerCase() : "";

      var acceptHeader = "*/*";
      if (extension === "pdf") {
        acceptHeader = "application/pdf";
      } else if (extension === "jpg" || extension === "jpeg" || extension === "png" || extension === "gif") {
        acceptHeader = "image/" + extension;
      }

      // Get File Stream
      var data = await fetch(`${process.env.REACT_APP_BASE_URL}/api/link/external?externalLinkId=${id}`, {
        method: "GET", 
        headers: {
          Accept: acceptHeader,
          ContentType: acceptHeader,
        },
      });

      if (data.ok) {
        const blob = await data.blob();
        const url = URL.createObjectURL(blob);
          if (blob && blob.size > 0) {
          // If PDF Or Image --> show file
          if (extension === "pdf") {
            const iframe = document.querySelector("iframe");
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
              // true for mobile device
              downloadBlob(blob, filename);
            }else{
              // false for not mobile device
              if (iframe?.src) iframe.src = url;
            }
          } else if (extension === "jpg" || extension === "jpeg" || extension === "png" || extension === "gif") {
            const image = document.querySelector('img');
            const blobTobase64 = await blobToBase64Async(blob);
            if (image?.src) image.src = blobTobase64;
          }
          // If any other file type --> download file
          else {
            if (calledOnce.current === false) {
              downloadBlob(blob, filename);
            }
            calledOnce.current = true;

            setText(true);
            setMessage("File downloaded");
          }
        }
        else {
          setText(true);
          setMessage("File expired")
        }
      } else {
        setText(true);
        setMessage(data.statusText);
      }
    } else {
      setText(true);
      setMessage(response.statusText);
    }
    setLoading(false);
  }

  const blobToBase64Async = async(blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onerror = (e) => reject(fileReader.error);
      fileReader.onloadend = (e) => {
        const dataUrl = fileReader.result as string;
        resolve(dataUrl);
      };
      fileReader.readAsDataURL(blob);
    });
  }

  const downloadBlob = (blob: Blob, name: string) => {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);
  
    // Create a link element
    const link = document.createElement("a");
  
    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = name;
  
    // Append link to the body
    document.body.appendChild(link);
  
    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', { 
        bubbles: true, 
        cancelable: true, 
        view: window 
      })
    );
  
    // Remove link from body
    document.body.removeChild(link);
  }

  useEffect(() => {
    fetchData(externalLinkId);
  },[externalLinkId]);


  return (
    <div style={{ width: '100%', height: '100%' }}>
      {loading ? <Spinner label="Loading..." style={{ marginTop: '20%'}} /> : null} 
      {text ? 
        <div style={{ marginTop: '20%', textAlign: 'center', color: 'red' }}>
          {message}
        </div> : null}
        <img id="image" alt="" src="" />
        <iframe 
          title="pdf" 
          src="" 
          style={{ height: 1200, width: "100%", border: "0px" }}
          onLoad={() => setLoading(false)}
        />
    </div>
  );
};

